<template>
  <div>
    <v-tabs show-arrows class="pl-4">
      <v-tab
        v-if="isCloud && canUpdateProject && projectType === 'premium'"
        key="billing"
        :to="`/project/${projectId}/billing`"
      >Details</v-tab>

      <v-tab
        v-if="projectType === ''"
        key="history"
        :to="`/project/${projectId}/history`"
      >{{ $t('history') }}
      </v-tab>

      <v-tab key="activity" :to="`/project/${projectId}/activity`">{{ $t('activity') }}</v-tab>

      <v-tab
        v-if="canUpdateProject"
        key="settings"
        :to="`/project/${projectId}/settings`"
      >{{ $t('settings') }}
      </v-tab>

      <v-tab
        v-if="projectType === ''"
        key="runners"
        :to="`/project/${projectId}/runners`"
      >
        {{ $t('runners') }}
        <v-icon class="ml-1" large color="hsl(348deg, 86%, 61%)">mdi-professional-hexagon</v-icon>
      </v-tab>

      <v-tab
        v-if="isCloud && canUpdateProject && projectType !== 'premium'"
        key="billing"
        :to="`/project/${projectId}/billing`"
      >Billing</v-tab>
    </v-tabs>

    <v-divider style="margin-top: -1px;" />
  </div>
</template>
<script>

export default {

  props: {
    projectId: Number,
    projectType: String,
    canUpdateProject: Boolean,
  },

  data() {
    return {
      id: null,
    };
  },

  computed: {
    isCloud() {
      return process.env.VUE_APP_BUILD_TYPE === 'cloud';
    },
  },

};
</script>
