<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>

    <v-toolbar flat>
      <v-app-bar-nav-icon @click="showDrawer()"></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ projectType === 'premium' ? 'Manage Subscription' : $t('dashboard') }}
      </v-toolbar-title>
    </v-toolbar>

    <DashboardMenu :project-id="projectId" :project-type="projectType"
      :can-update-project="can(USER_PERMISSIONS.updateProject)" />

    <div class="px-6 pt-8">
      <v-row>
        <v-col sm="6" md="6" lg="4" xl="3" v-for="post in posts" :key="post.title">
          <v-card style="background: rgba(133, 133, 133, 0.06);">
            <v-img :src="post.image" height="200px"></v-img>

            <v-card-subtitle class="pb-2">{{ post.date | formatDate2 }}</v-card-subtitle>

            <v-card-title class="pt-0">{{ post.title }}</v-card-title>

            <v-card-subtitle>{{ post.subtitle }}</v-card-subtitle>

            <v-card-actions>
              <v-btn text @click="post.show = !post.show">
                Read more
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn icon @click="post.show = !post.show">
                <v-icon>{{ post.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="post.show">
                <v-divider></v-divider>

                <v-card-text>
                  <div v-html="post.text"></div>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </div>

  </div>
</template>
<script>
import DashboardMenu from '@/components/DashboardMenu.vue';
import PermissionsCheck from '@/components/PermissionsCheck';
import { USER_PERMISSIONS } from '@/lib/constants';

export default {
  props: {
    projectId: Number,
    projectType: String,
  },
  mixins: [PermissionsCheck],
  components: {
    DashboardMenu,
  },

  data() {
    return {
      USER_PERMISSIONS,
      posts: [{
        date: '2024-12-20',
        image: 'https://semaphoreui.com/uploads/v2.11.0/poster.jpg',
        title: 'Terraform and OpenTofu HTTP backend',
        subtitle: 'Now you can use Semaphore to store the state for Terraform and OpenTofu.',
        text: '<div>'
          + '<p>Storing the state of your Terraform and OpenTofu deployments in Semaphore '
          + 'enhances the efficiency and reliability of your infrastructure management. '
          + 'By leveraging Semaphore\'s state storage, you ensure that the state files are '
          + 'securely saved and easily accessible during your continuous integration and deployment '
          + 'processes. This centralized approach prevents state file conflicts and streamlines '
          + 'collaboration among team members. Additionally, integrating state management with '
          + 'Semaphore simplifies your workflow by reducing dependencies on external storage '
          + 'solutions.</p>'
          + '</div>',
        show: false,
      }, {
        date: '2024-11-22',
        image: 'https://semaphoreui.com/uploads/v2.10.43/poster.webp',
        title: 'UI Improvements',
        subtitle: 'Expandable Task Log and Inventory dialogs.',
        text: '<div>'
          + '<h3 class="mb-2">Expandable task log dialog</h3>'
          + '<p>The task log dialog in Semaphore UI can now be expanded, providing more space to view detailed logs and monitor tasks more effectively. This feature improves user experience by making it easier to analyze and troubleshoot processes..</p>'
          + '<h3 class="mb-2">Expandable inventory dialog</h3>'
          + '<p>The inventory dialog in Semaphore UI is now expandable, giving users the flexibility to manage and edit inventory data with greater convenience. This enhancement ensures a more comfortable and efficient workflow for handling complex inventory details.</p>'
          + '</div>',
        show: false,
      }, {
        date: '2024-11-21',
        image: 'https://semaphoreui.com/uploads/v2.10.41/poster.webp',
        title: 'Project Runners',
        subtitle: 'Allow joining a remote runner to a specified project.',
        text: '<ol>'
          + '<li class="mb-2">Project Runners allow dedicated resources to be allocated to specific projects, avoiding contention and improving performance.</li>'
          + '<li class="mb-2">You can scale runners independently for each project based on workload, making it easier to manage high-demand projects without impacting others.</li>'
          + '<li class="mb-2">Administrators can assign and monitor runners at the project level, providing better control over execution environments and resource utilization.</li>'
          + '</ol>',
        show: false,
      }],
    };
  },

};
</script>
